import React from "react";

const ResourceItem: React.FC<{ title: string; description: string }> = ({
  title,
  description,
}) => (
  <div className="mb-6">
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const Resource: React.FC = () => {
  const resources = [
    {
      title: "Hướng dẫn Sử dụng",
      description: "Hướng dẫn sử dụng chi tiết cho từng tính năng",
    },
    {
      title: "Video Tutorial",
      description: "Video tutorial về cách tối ưu hóa chiến dịch marketing",
    },
    {
      title: "Blog Marketing",
      description: "Blog với các bài viết về xu hướng marketing mới nhất",
    },
    {
      title: "Webinar Hàng tháng",
      description: "Webinar hàng tháng về chiến lược tiếp thị trên Facebook",
    },
    {
      title: "Tài liệu API",
      description: "Tài liệu API cho các nhà phát triển muốn tích hợp công cụ",
    },
  ];

  return (
    <div className="container mx-auto px-4 py-12">
      <h1 className="text-4xl font-bold mb-8 text-center">
        Tài nguyên và Công cụ Marketing
      </h1>
      <div className="grid md:grid-cols-2 gap-8">
        {resources.map((resource, index) => (
          <ResourceItem
            key={index}
            title={resource.title}
            description={resource.description}
          />
        ))}
      </div>
    </div>
  );
};

export default Resource;
