import React from "react";

const SolutionItem: React.FC<{ title: string; description: string }> = ({
  title,
  description,
}) => (
  <div className="mb-6">
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const Solution: React.FC = () => {
  const solutions = [
    {
      title: "Lấy UID Thông minh",
      description: "Tự động hóa quy trình lấy UID từ các trang Facebook",
    },
    {
      title: "Gửi Tin nhắn Tự động",
      description: "Công cụ gửi tin nhắn hàng loạt với tùy chỉnh nội dung",
    },
    {
      title: "Bình luận Thông minh",
      description: "Hệ thống bình luận tự động với khả năng cá nhân hóa",
    },
    {
      title: "Phân tích Dữ liệu",
      description: "Quản lý và phân tích dữ liệu khách hàng hiệu quả",
    },
    {
      title: "Tích hợp Đa nền tảng",
      description: "Tích hợp seamless với các chiến dịch marketing đa kênh",
    },
  ];

  return (
    <div className="container mx-auto px-4 py-12">
      <h1 className="text-4xl font-bold mb-8 text-center">
        Giải pháp Marketing Toàn diện cho Facebook
      </h1>
      <div className="grid md:grid-cols-2 gap-8">
        {solutions.map((solution, index) => (
          <SolutionItem
            key={index}
            title={solution.title}
            description={solution.description}
          />
        ))}
      </div>
    </div>
  );
};

export default Solution;
