import React from "react";
import icon_window from "../assets/icon-window.svg";
import icon_mac from "../assets/icon-mac.svg";
import {
  LINK_DOWNLOAD_MAC_ARM,
  LINK_DOWNLOAD_MAC_INTEL,
  LINK_DOWNLOAD_WIN,
} from "../constant/constants";

const Download: React.FC = () => {
  return (
    <main className="container mx-auto px-4 py-8 sm:py-12">
      <h1 className="text-3xl sm:text-4xl font-bold text-center mb-4">
        Download MarTool
      </h1>
      <p className="text-center text-gray-600 mb-8 sm:mb-12 px-4">
        "If you don't try MarTool, you won't become the superhero you were meant
        to be"
      </p>

      <div className="flex flex-col sm:flex-row justify-center items-center sm:space-x-8 space-y-8 sm:space-y-0">
        <div className="bg-white rounded-2xl shadow-lg p-8 w-full sm:w-80 text-center">
          <img
            src={icon_window}
            alt="Windows"
            className="w-16 h-16 mx-auto mb-6"
          />
          <h2 className="text-2xl font-semibold mb-6">Windows</h2>
          <a
            href={LINK_DOWNLOAD_WIN}
            className="text-blue-600 hover:underline flex items-center justify-center"
          >
            <svg
              className="w-4 h-4 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
            Window (64 bit)
          </a>
        </div>
        <div className="bg-white rounded-2xl shadow-lg p-8 w-full sm:w-80 text-center">
          <img src={icon_mac} alt="macOS" className="w-16 h-16 mx-auto mb-6" />
          <h2 className="text-2xl font-semibold mb-6">macOS</h2>
          <div className="space-y-2">
            <a
              href={LINK_DOWNLOAD_MAC_INTEL}
              className="text-blue-600 hover:underline block"
            >
              MacOS (Intel)
            </a>
            <a
              href={LINK_DOWNLOAD_MAC_ARM}
              className="text-blue-600 hover:underline block"
            >
              MacOS (Apple Silicon)
            </a>
          </div>
        </div>
      </div>
      {/* <div className="mt-12 text-center">
        <button className="bg-blue-600 text-white px-6 py-3 rounded-full text-lg font-semibold">
          Download previous version
        </button>
      </div> */}
    </main>
  );
};

export default Download;
