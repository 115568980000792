import React from "react";

interface PricingPlan {
  name: string;
  price: string;
  features: string[];
}

const PricingCard: React.FC<PricingPlan> = ({ name, price, features }) => (
  <div className="bg-white rounded-lg shadow-lg p-6">
    <h3 className="text-2xl font-bold mb-4">{name}</h3>
    <p className="text-4xl font-bold mb-6">
      {price}
      <span className="text-sm text-gray-500">/tháng</span>
    </p>
    <ul className="mb-6">
      {features.map((feature, index) => (
        <li key={index} className="mb-2 flex items-center">
          <svg
            className="w-4 h-4 mr-2 text-green-500"
            fill="currentColor"
            viewBox="0 0 20 20"
          >
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
          {feature}
        </li>
      ))}
    </ul>
    <button className="w-full bg-blue-600 text-white py-2 rounded-lg hover:bg-blue-700 transition duration-200">
      Chọn gói
    </button>
  </div>
);

const Pricing: React.FC = () => {
  const plans: PricingPlan[] = [
    {
      name: "Gói Cơ bản",
      price: "$X",
      features: ["Feature 1", "Feature 2", "Feature 3"],
    },
    {
      name: "Gói Chuyên nghiệp",
      price: "$Y",
      features: ["Feature 1", "Feature 2", "Feature 3", "Feature 4"],
    },
    {
      name: "Gói Doanh nghiệp",
      price: "$Z",
      features: [
        "Feature 1",
        "Feature 2",
        "Feature 3",
        "Feature 4",
        "Feature 5",
      ],
    },
    {
      name: "Gói Tùy chỉnh",
      price: "Liên hệ",
      features: [
        "Tất cả tính năng",
        "Hỗ trợ ưu tiên",
        "Tùy chỉnh theo yêu cầu",
      ],
    },
  ];

  return (
    <div className="container mx-auto px-4 py-12">
      <h1 className="text-4xl font-bold mb-8 text-center">
        Gói Dịch vụ Linh hoạt cho Mọi Nhu cầu
      </h1>
      <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
        {plans.map((plan, index) => (
          <PricingCard key={index} {...plan} />
        ))}
      </div>
      <p className="text-center mt-8 text-lg">
        Dùng thử miễn phí 14 ngày cho tất cả các gói
      </p>
    </div>
  );
};

export default Pricing;
