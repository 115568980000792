import React from "react";

const GuidelineItem: React.FC<{ title: string; description: string }> = ({
  title,
  description,
}) => (
  <div className="mb-6">
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </div>
);

const Guideline: React.FC = () => {
  const guidelines = [
    {
      title: "Thiết lập Tài khoản",
      description: "Cách thiết lập tài khoản và cấu hình ban đầu",
    },
    {
      title: "Tạo Chiến dịch",
      description: "Hướng dẫn tạo chiến dịch marketing hiệu quả",
    },
    {
      title: "Tối ưu hóa Nội dung",
      description: "Các kỹ thuật tối ưu hóa nội dung tin nhắn và bình luận",
    },
    {
      title: "Phân tích Hiệu quả",
      description: "Cách sử dụng công cụ phân tích để đo lường hiệu quả",
    },
    {
      title: "Tuân thủ Chính sách",
      description: "Thực hành tốt nhất để tuân thủ chính sách của Facebook",
    },
  ];

  return (
    <div className="container mx-auto px-4 py-12">
      <h1 className="text-4xl font-bold mb-8 text-center">
        Hướng dẫn Sử dụng và Thực hành Tốt nhất
      </h1>
      <div className="grid md:grid-cols-2 gap-8">
        {guidelines.map((guideline, index) => (
          <GuidelineItem
            key={index}
            title={guideline.title}
            description={guideline.description}
          />
        ))}
      </div>
    </div>
  );
};

export default Guideline;
