import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/logo.png";

const LanguageDropdown: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative inline-block text-left">
      <div>
        <button
          type="button"
          className="inline-flex justify-center items-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
          onClick={() => setIsOpen(!isOpen)}
        >
          EN
          <svg
            className="-mr-1 ml-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>

      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            <a
              href="#"
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              role="menuitem"
            >
              EN
            </a>
            <a
              href="#"
              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
              role="menuitem"
            >
              VN
            </a>
            {/* Thêm các ngôn ngữ khác ở đây */}
          </div>
        </div>
      )}
    </div>
  );
};

const MenuItem: React.FC<{ to: string; children: React.ReactNode }> = ({
  to,
  children,
}) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <li className="relative group">
      <Link
        to={to}
        className={`px-3 py-2 rounded-md text-sm font-medium transition-colors duration-200 ease-in-out
            ${
              isActive
                ? "text-blue-600 bg-blue-100"
                : "text-gray-700 hover:text-blue-600 hover:bg-blue-50"
            }`}
      >
        {children}
      </Link>
      <span
        className={`absolute bottom-0 left-0 w-full h-0.5 bg-blue-600 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-200 ease-in-out
            ${isActive ? "scale-x-100" : ""}`}
      ></span>
    </li>
  );
};

const Navigation: React.FC = () => {
  return (
    <nav>
      <ul className="flex space-x-1">
        <MenuItem to="/solution">Solution</MenuItem>
        <MenuItem to="/resource">Resource</MenuItem>
        <MenuItem to="/guideline">Guideline</MenuItem>
        <MenuItem to="/pricing">Pricing</MenuItem>
        <MenuItem to="/contact">Contact</MenuItem>
      </ul>
    </nav>
  );
};

const Header: React.FC = () => {
  return (
    <header className="flex justify-between items-center py-4 px-6">
      <Link to="/">
        <img src={logo} alt="MarTool Logo" className="h-8" />
      </Link>
      {/* <Navigation /> */}
      <div className="flex items-center space-x-4">
        <button className="border border-gray-300 px-4 py-2 rounded">
          Download
        </button>
        <LanguageDropdown />
      </div>
    </header>
  );
};

export default Header;
